module.exports = [
  { status: 'new', description: 'New patient without demographics' },
  { status: 'eligibility', description: 'Processing insurance eligibility' },
  { status: 'ineligible', description: 'Insurance is not eligible for RPM or CCM' },
  { status: 'assessment', description: 'Assessment in progress' },
  { status: 'onboard', description: 'Onboard processing' },
  { status: 'device', description: 'Onboarded and RPM devices are enroute' },
  { status: 'active', description: 'Onboarded, Active with RPM Devices and/or CCM' },
  { status: 'inactive', description: 'Discontinued the program after being active' },
  { status: 'fto', description: 'Failed to Onboard after 3 attempts' },
  { status: 'paused', description: 'Temporarily not taking RPM reading or CCM sessions (Enter the reason and resumption date in comments)' },
  { status: 'deceased', description: 'Deceased' },
  { status: 'declined', description: 'Patient declined to participate and provided the reason to be documented in Physician’s EHR' },
  { status: 'self_pay', description: 'Patient’s insurance is ineligible but chooses to pay out of pocket' },
];